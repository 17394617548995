import { Card, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../App.css'
import { Link } from "react-router-dom";
import portrait from "../../Images/Thumbnail/POTRAIT PHOTOGRAPHY.jpg";
import commercial from "../../Images/Thumbnail/COMMERCIAL PHOTOGRAPHY.jpg";
import kids from "../../Images/Thumbnail/KIDS PHOTOGRAPHY.jpg";
import Error from '../../Pages/Error';


const { Meta } = Card;
const Cards = () => {

    const [card, setCard] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);  // State for loading

    useEffect(() => {
        //debugger;
        const list = localStorage.getItem('list-url');
        const listExpiry = localStorage.getItem('list-url-expry');
        const now = new Date();
        const expryDate = new Date(JSON.parse(listExpiry));
        if (!list || (listExpiry && now > expryDate)) {
            axios.get(`${process.env.REACT_APP_API_URL}/get-category-list`)
                .then(response => {
                    debugger
                    // console.log(" Home Card Response--->", response);
                    var updatedCategories = response.data.categories.map((data) => {
                        return { ...data, url: `/${data.name}` };
                    });
                    const arrayLength = updatedCategories.length;
                    const randomNumber = Math.floor(Math.random() * (arrayLength - 3)) + 3;

                    // console.log("category", updatedCategories, randomNumber);
                    const slicedCategories = updatedCategories.slice(randomNumber - 3, randomNumber);
                    setCard(slicedCategories);
                    setError(false)

                    localStorage.setItem('list-url', JSON.stringify(updatedCategories));

                    // Assuming `ttl` is a property in `updatedCategories` with the expiry date
                    const resExpiry = new Date(response.data.ttl);
                    localStorage.setItem('list-url-expry', JSON.stringify(resExpiry));
                    setLoading(false); // Set loading to false when data is fetched
                })
                .catch(error => {
                    if (list) {
                        const cardList = JSON.parse(list)
                        const arrayLength = cardList.length;
                        const randomNumber = Math.floor(Math.random() * (arrayLength - 3)) + 3;
                        // If data exists and is not expired, use the stored data
                        setCard(cardList.slice(randomNumber - 3, randomNumber));
                        // console.log("Home Card old Response-->", JSON.parse(list));
                    }
                    else {
                        setError(true)
                    }
                    // console.error('There was an error fetching the category list!', error);
                    setLoading(false); // Set loading to false in case of error
                });
        }
        else {
            const cardList = JSON.parse(list)
            const arrayLength = cardList.length;
            const randomNumber = Math.floor(Math.random() * (arrayLength - 3)) + 3;
            // If data exists and is not expired, use the stored data
            setCard(cardList.slice(randomNumber - 3, randomNumber));
            // console.log("Home Card old Response-->", JSON.parse(list));
            setLoading(false);
        }
    }, []);
    const cardData = [
        {
            id: 1,
            imageUrl: portrait,
            title: 'Portrait',
            content: 'In the realm of portraits,' +
                ' the camera becomes a mirror that reflects ' +
                'not only physical features but also the essence of a soul',
            url: '/portrait'
        },
        {
            id: 2,
            imageUrl: commercial,
            title: 'Commercial Photography',
            content: 'Through commercial photography, brands come to life, ' +
                'creating visual narratives that leave a lasting impression.',
            url: '/commercial'
        },
        {
            id: 3,
            imageUrl: kids,
            title: 'Kids Photography',
            content: 'In the world of kids photography, tiny hands, twinkling eyes, ' +
                'and infectious smiles become the stars of the show',
            url: '/kidsphotography'
        },
        // { id: 4, title: 'Portfolio', imageUrl: img, content: 'Fashion', url:'/portfolio' },
        // { id: 5, title: 'Maternity', imageUrl: img, content: 'Fashion', url:'/maternity'},
        // { id: 6, title: 'Corporate', imageUrl: img, content: 'Fashion', url:'/fashion'},
        // { id: 7, title: 'Baby Shoot',imageUrl: img, content: 'Fashion', url:'/babyshoot' },
        // { id: 8, title: 'Puberty', imageUrl: img, content: 'Fashion', url:'/fashion'},
        // { id: 9, title: 'Concert', imageUrl: img, content: 'Fashion', url:'/fashion'},
        // { id: 9, title: 'Card 5', imageUrl: img1, content: 'Fashion', url:'/fashion' },
        // Add more cards as needed
    ];

    function reveal() {
        var reveals = document.querySelectorAll(".reveal");

        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = 150;

            if (elementTop < windowHeight - elementVisible) {
                reveals[i].classList.add("active");
            } else {
                reveals[i].classList.remove("active");
            }
        }
    }

    window.addEventListener("scroll", reveal);
    return (

        <div className="container reveal">
            {error ? <Error /> : (
                <Row gutter={[16, 16]}>
                    {card.map(card => (
                        <Col xs={24} sm={24} md={24} lg={8} key={card.id}>
                            {/*<Card title={card.title}>*/}
                            {/*    <div className="card-image-container">*/}
                            {/*        <img src={card.imageUrl} alt={card.title} />*/}
                            {/*    </div>*/}
                            {/*</Card>*/}
                            <Link to={`works/${card.id}`}>
                                <Card className="cards"
                                    cover={
                                        <img alt="example" src={card.image} loading="lazy"
                                        />
                                    }
                                    style={{ alignItems: "center" }}
                                >
                                    <Meta className="cards-name"
                                        title={card.name}
                                        style={{ textAlign: "center" }}
                                    // description="This is the description"
                                    />
                                    <div className="content">{card.name}</div>
                                </Card>
                            </Link>
                        </Col>


                    ))}
                </Row>)}

        </div>

    )
}

export default Cards;