import React, { useEffect, useState } from 'react';
import '../App.css';
import { Button, Image } from 'antd';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { Oval } from 'react-loader-spinner'; // Import the loader component

const ViewAll = () => {
    const { subId } = useParams();

    const [card, setCard] = useState([]);
    const [loading, setLoading] = useState(true); // State for loading
    const [error, setError] = useState(false);  // State for loading

    useEffect(() => {
        const list = localStorage.getItem('viewAll-url');
        const listExpiry = localStorage.getItem('viewAll-url-expry');
        const now = new Date();
        const expiryDate = new Date(JSON.parse(listExpiry));
        //debugger;
        if (!list || (listExpiry && now > expiryDate)) {
            axios.get(`${process.env.REACT_APP_API_URL}/get-gallery-image-list`)
                .then(response => {
                    // console.log("Response--->", response);

                    // console.log(response.data.gallery_images);
                    setCard(response.data.gallery_images);
                    localStorage.setItem('viewAll-url', JSON.stringify(response.data.gallery_images));

                    // Assuming `ttl` is a property in `updatedCategories` with the expiry date
                    const resExpiry = new Date(response.data.ttl);
                    localStorage.setItem('viewAll-url-expry', JSON.stringify(resExpiry));
                    setLoading(false); // Set loading to false when data is fetched
                    setError(false)
                })
                .catch(error => {
                    if (list) {
                        setCard(JSON.parse(list));
                        // console.log("old Response-->", JSON.parse(list));
                        setLoading(false);
                    }
                    else {
                        setError(true)
                    }
                    // console.error('There was an error fetching the category list!', error);
                    setLoading(false); // Set loading to false in case of error
                });
        }
        else {
            // If data exists and is not expired, use the stored data
            setCard(JSON.parse(list));
            // console.log("old Response-->", JSON.parse(list));
            setLoading(false);
            setError(false)
        }
    }, []);

    return (
        <div className="app-gallery">
            {loading ? ( // Show loader while data is being fetched
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Oval
                        height={80}
                        width={80}
                        color="rgb(57, 35, 19)"
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#f3f3f3"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </div>
            ) : (
                <div style={{ padding: "10% 1% 1% 1%", backgroundColor: "white", margin: "auto" }}>
                    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
                        <Masonry columnsCount={3} gutter="10px">
                            {card.map((data) => (
                                <Image
                                    key={data.id}
                                    src={data.image}
                                    style={{ width: "100%", display: "block" }}
                                    loading="lazy"
                                />
                            ))}
                        </Masonry>
                    </ResponsiveMasonry>
                    <br /><br />
                </div>
            )}
        </div>
    );
}

export default ViewAll;

