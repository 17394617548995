import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "antd";
import { Link } from "react-router-dom";
import Meta from "antd/es/card/Meta";
import axios from "axios";
import { Oval } from 'react-loader-spinner'; // Import the loader component

const Works = () => {
    const [card, setCard] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);  // State for loading

    useEffect(() => {
        const list = localStorage.getItem('list-url');
        const listExpiry = localStorage.getItem('list-url-expry');
        const now = new Date();
        const expiryDate = new Date(JSON.parse(listExpiry));
        //debugger;
        if (!list || (listExpiry && now > expiryDate)) {
            axios.get(`${process.env.REACT_APP_API_URL}/get-category-list`)
                .then(response => {
                    // console.log("Response--->", response);
                    const updatedCategories = response.data.categories.map((data) => {
                        return { ...data, url: `/${data.name}` };
                    });
                    // console.log(updatedCategories);
                    setCard(updatedCategories);
                    localStorage.setItem('list-url', JSON.stringify(updatedCategories));

                    // Assuming `ttl` is a property in `updatedCategories` with the expiry date
                    const resExpiry = new Date(response.data.ttl);
                    localStorage.setItem('list-url-expry', JSON.stringify(resExpiry));
                    setLoading(false); // Set loading to false when data is fetched
                    setError(false)
                })
                .catch(error => {
                    if (list) {
                        setCard(JSON.parse(list));
                        // console.log("old Response-->", JSON.parse(list));
                        setLoading(false);
                    }
                    else {
                        setError(true)
                    }
                    // console.error('There was an error fetching the category list!', error);
                    setLoading(false); // Set loading to false in case of error
                });
        }
        else {
            // If data exists and is not expired, use the stored data
            setCard(JSON.parse(list));
            // console.log("old Response-->", JSON.parse(list));
            setLoading(false);
            setError(false)
        }
    }, []);

    return (
        <div className="Works-Container">
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Oval
                        height={80}
                        width={80}
                        color="rgb(57, 35, 19)"
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#f3f3f3"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </div>
            ) : (error ? <p>Error</p> :
                <Row gutter={[16, 16]}>
                    {card.map(card => (
                        <Col xs={24} sm={24} md={8} lg={8} key={card.id}>
                            <Link to={`/works/${card.id}`}>
                                <Card className="cards"
                                    cover={
                                        <img alt="example" src={card.image} loading="lazy" height="280px" />
                                    }
                                    style={{ alignItems: "center" }}
                                >
                                    <Meta className="cards-name"
                                        title={card.name}
                                        style={{ textAlign: "center" }}
                                    />
                                </Card>
                            </Link>
                        </Col>
                    ))}
                </Row>
            )}
        </div>
    );
}

export default Works;
