import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../App.css'
import { Row, Col, Card } from 'antd';
import { Oval } from 'react-loader-spinner';
import Error from './Error'
// import video1 from '../Images/Videos/A grand Kongu Wedding _ Witness Love Unfold_ The Naveen & Preethi Story _ Almighty Pictures.mp4'
// import video2 from '../Images/Videos/Ranjithame _ Wedding Portfolio Video _ Glimpse _ Varisu _ Cinematic Video _ Salem Wedding.mp4'
// import video3 from '../Images/Videos/IndhuShansun & Arvind _ Wedding Portfolio video _ Glimpse _ Marriage _ Coimbatore _ FRAMES BY SARAN.mp4';
import BackButton from "../Components/BackButton/BackButton";
// import { useEffect } from 'react';
const VideoList = () => {
    const [card, setCard] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);  // State for loading


    useEffect(() => {

        axios.get(`${process.env.REACT_APP_API_URL}/get-film-url-list`)
            .then(response => {
                // console.log("Response--->", response);
                // console.log("New Response-->", response.data.film_urls);
                setCard(response.data.film_urls);
                setError(false)

                setLoading(false); // Set loading to false when data is fetched
            })
            .catch(error => {
                setError(true)
                // console.error('There was an error fetching the category list!', error);
                setLoading(false); // Set loading to false in case of error
            });

    }, []);

    const videos = [
        {
            id: 1,
            title: 'A Grand Kongu Wedding',
            url: "https://www.youtube.com/embed/tpj3tiBrUz0",
        },
        {
            id: 2,
            title: 'Video 2',
            url: "https://www.youtube.com/embed/gAp9fH7plpE",
        },
        {
            id: 3,
            title: 'Video 2',
            url: "https://www.youtube.com/embed/5gYS0aBPZjg",
        },
        {
            id: 4,
            title: 'Video 2',
            url: "https://www.youtube.com/embed/MtWUosU-PeI",
        },
        {
            id: 5,
            title: 'Video 2',
            url: "https://www.youtube.com/embed/MO85XT3zR70",
        },
        {
            id: 6,
            title: 'Video 2',
            url: "https://www.youtube.com/embed/h2_n5mQk1do",
        },
        {
            id: 7,
            title: 'Video 2',
            url: "https://www.youtube.com/embed/Jzl1BQgeKQ4",
        },
        {
            id: 8,
            title: 'Video 2',
            url: "https://www.youtube.com/embed/pEY_x0jCVt8",
        },
        {
            id: 9,
            title: 'Video 2',
            url: "https://www.youtube.com/embed/PnQ20bITTec",
        },
        {
            id: 10,
            title: 'Video 2',
            url: "https://www.youtube.com/embed/PjrCpS09Tx0",
        },
        // Add more video objects as needed
    ];

    return (
        loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Oval
                    height={80}
                    width={80}
                    color="rgb(57, 35, 19)"
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#f3f3f3"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
            </div>
        ) : (error ? (<Error />) : (
            <div className="Video">
                {/*<div className="Backbutton">*/}
                {/*    <BackButton />*/}
                {/*</div>*/}
                <div className="VideoList" >
                    <h1>FILMS</h1>
                    <Row gutter={[12, 12]}>
                        {card.map((video) => (
                            <Col key={video.id} xs={24} sm={24} md={24} lg={8} xl={8}>
                                <Card
                                    hoverable
                                    cover={<iframe loading="lazy" height="300" width="250" className="videoList" src={video.url} />}
                                    style={{ margin: "auto" }}
                                    title={null}
                                >
                                    <Card.Meta title={video.title} />
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>))

    );
};

export default VideoList;
