import { useRef, useState } from "react";
import '../../App.css';
import emailjs from '@emailjs/browser';
import { Button, Form, Input, Modal, Typography, message } from 'antd';

const { Title } = Typography;

const ContactForm = () => {
    const form = useRef();
    const [formFields] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const sendEmail = (values) => {
        emailjs.send('service_xtkhuc6', 'template_obuooga', values, 'NZWYcFXl8TPeyJoiw')
            .then(() => {
                formFields.resetFields();
                setIsModalVisible(false);
                message.success('Email sent successfully,we will get back soon', 5);
            })
            .catch((error) => {
                console.error('Error sending email:', error);
            });
    };

    return (
        <section>
            <div className="Contact-Form">
                <Button type="primary" className="contact_btn" onClick={showModal}>
                    FOR ENQUIRY
                </Button>

                <Modal
                    title="CONTACT FORM"
                    visible={isModalVisible}
                    onCancel={handleCancel}
                    footer={null}
                >
                    <Form ref={form} form={formFields} className="form-control" onFinish={sendEmail}>
                        <Form.Item name="name">
                            <Input
                                className="form-item"
                                type="text"
                                placeholder="Your Name"
                                required
                            />
                        </Form.Item>
                        <Form.Item name="service">
                            <Input
                                className="form-item"
                                type="text"
                                placeholder="Service Required"
                                required
                            />
                        </Form.Item>
                        <Form.Item name="email">
                            <Input
                                className="form-item"
                                type="email"
                                placeholder="Your Email"
                                required
                            />
                        </Form.Item>
                        <Form.Item name="number">
                            <Input
                                className="form-item"
                                type="text"
                                placeholder="Your Contact number"
                                required
                            />
                        </Form.Item>
                        <Form.Item name="message">
                            <Input.TextArea
                                className="form-item"
                                placeholder="Your Message"
                                rows={4}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" type="primary" className="submit-button">
                                Send
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        </section>
    );
};

export default ContactForm;
