import { Card, Col, Row } from "antd";
import { Link, useParams } from "react-router-dom";
import Error from "./Error"
import Meta from "antd/es/card/Meta";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Oval } from 'react-loader-spinner'; // Import the loader component

const SubCategories = () => {
    const { categoryId } = useParams();
    const [card, setCard] = useState([]);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true); // State for loading

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/get-sub-category-list/${categoryId}`)
            .then(response => {
                // console.log("Response--->", response);
                const updatedCategories = response.data.subcategories.map((data) => {
                    return { ...data, url: `/${data.name}` };
                });
                // console.log(updatedCategories);
                setCard(updatedCategories);
                setLoading(false); // Set loading to false when data is fetched
            })
            .catch(error => {
                setError(true);
                // console.error('There was an error fetching the category list!', error);
                setLoading(false); // Set loading to false in case of error
            });
    }, [categoryId]); // Add categoryId as a dependency

    return (
        <div className="Works-Container">
            {loading ? ( // Show loader while data is being fetched
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Oval
                        height={80}
                        width={80}
                        color="rgb(57, 35, 19)"
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#f3f3f3"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </div>
            ) : (error ? (<Error />) : (
                <Row gutter={[16, 16]}>
                    {card.map(card => (
                        <Col xs={24} sm={24} md={8} lg={8} key={card.id}>
                            <Link to={`/gallery/${card.id}`}>
                                <Card className="cards"
                                    cover={
                                        <img alt="example" src={card.image} loading="lazy" height="280px" />
                                    }
                                    style={{ alignItems: "center" }}
                                >
                                    <Meta className="cards-name"
                                        title={card.name}
                                        style={{ textAlign: "center" }}
                                    />
                                </Card>
                            </Link>
                        </Col>
                    ))}
                </Row>)
            )}
        </div>
    );
}

export default SubCategories;
